import React from 'react';
import reminderScheduledImg from '../../assets/images/reminder-scheduled.svg';
import { Form, Separator } from '@bokadirekt/apps-reviews-form';
import styled from 'styled-components';
import AppstoreBanner from './AppstoreBanner';
import LabeledSection from './LabeledSection';
import { DateTime } from 'luxon';

const TopPadding = styled.div`
  display: block;
  height: 32px;
  @media (min-width: 768px) {
    height: 0px;
  }
`;

const Title = styled.div`
  font-family: Gibson-Regular;
  font-size: 24px;
  line-height: 29px;
  color: #2a2e43;
  text-align: center;
`;

const Subtitle = styled.div`
  font-family: Gibson-Regular;
  font-size: 17px;
  line-height: 24px;
  color: #454f63;
  text-align: center;
`;

type ReminderScheduledProps = {
  reminderTs: Date;
  onViewInApp?: () => void;
};

const ReminderScheduled: React.FC<ReminderScheduledProps> = ({
  reminderTs,
  onViewInApp
}: ReminderScheduledProps) => {
  return (
    <>
      <TopPadding />

      <Form paddingTop={0}>
        <img
          src={reminderScheduledImg}
          alt="Header"
          style={{ width: '100%' }}
        />
        <Separator height={20} />
        <Title>Du är klar!</Title>
        <Subtitle>
          {/* Vi påminner: mån 28 juni 2019, kl 09:30 */}
          Vi påminner:{' '}
          {DateTime.fromJSDate(reminderTs)
            .setLocale('sv')
            .toFormat('EEE d MMMM yyyy, kl HH:mm')
            .toLowerCase()}
        </Subtitle>
        <Separator height={104} withBorder={true} />
        <Separator height={24} />
        <LabeledSection title="HÄMTA VÅR APP">
          <AppstoreBanner onClick={onViewInApp} />
        </LabeledSection>
      </Form>
    </>
  );
};

export default ReminderScheduled;
