import { Separator } from '@bokadirekt/apps-reviews-form';
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
`;

const Title = styled.div`
  font-family: Gibson-Regular;
  font-size: 12px;
  line-height: 14px;
  color: #78849e;
  text-transform: uppercase;
`;

type LabeledSectionProps = React.PropsWithChildren<{
  title: string;
}>;

const LabeledSection: React.FC<LabeledSectionProps> = ({
  title,
  children
}: LabeledSectionProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Separator height={15} />
      <div>{children}</div>
    </Container>
  );
};

export default LabeledSection;
