import { Separator } from '@bokadirekt/apps-reviews-form';
import * as React from 'react';
import styled from 'styled-components';

import iconSun from '../../assets/images/icon_sun.png';
import iconMoon from '../../assets/images/icon_moon.png';
import iconCouch from '../../assets/images/icon_couch.png';
import iconCal1 from '../../assets/images/icon_cal1.png';
import iconCal3 from '../../assets/images/icon_cal3.png';
import iconCal5 from '../../assets/images/icon_cal5.png';
import iconCal9 from '../../assets/images/icon_cal9.png';

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  margin: 8px;
  text-align: center;
  min-height: 98px;
  border-radius: 8px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: Gibson-Regular;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e43;
`;

const Subtitle = styled.div`
  font-family: Gibson-Regular;
  font-size: 12px;
  line-height: 21px;
  color: #78849e;
`;

const Icons = {
  sun: iconSun,
  moon: iconMoon,
  couch: iconCouch,
  cal1: iconCal1,
  cal3: iconCal3,
  cal5: iconCal5,
  cal9: iconCal9
};

type TimeCardProps = {
  icon: 'sun' | 'moon' | 'couch' | 'cal1' | 'cal3' | 'cal5' | 'cal9';
  title: string;
  subtitle?: string;
  onClick?: () => void;
};

const TimeCard: React.FC<TimeCardProps> = ({
  icon,
  title,
  subtitle,
  onClick
}: TimeCardProps) => {
  return (
    <Card onClick={onClick}>
      <Separator height={13} />
      <img src={Icons[icon]} width={32} height={32} alt="Icon" />
      <Separator height={6} />
      <Title>{title}</Title>
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Card>
  );
};

export default TimeCard;
