import * as React from 'react';
import styled from 'styled-components';
import {
  GreenLogo,
  Separator,
  FullStar,
  HalfStar
} from '@bokadirekt/apps-reviews-form';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextBox = styled.div`
  margin-left: 12px;
  flex: 1;
`;

const Title = styled.div`
  font-family: Gibson-Regular;
  font-size: 16px;
  line-height: 19px;
  color: #2a2e43;
`;
const Subtitle = styled.div`
  font-family: Gibson-Regular;
  font-size: 14px;
  line-height: 20px;
  color: #78849e;
`;

const ButtonBox = styled.div``;

const InstallButton = styled.div`
  font-family: Gibson-Semibold;
  font-size: 13px;
  line-height: 16px;
  color: #007df8;
  height: 31px;
  border-radius: 15px;
  background-color: #eeeef0;
  vertical-align: text-middle;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const StarsBox = styled.div`
  display: flex;
  width: 80px;
  justify-content: space-between;
`;

type AppstoreBannerProps = {
  onClick?: () => void;
};

const AppstoreBanner: React.FC<AppstoreBannerProps> = ({
  onClick
}: AppstoreBannerProps) => {
  return (
    <Container onClick={onClick}>
      <GreenLogo />
      <TextBox>
        <Title>Bokadirekt</Title>
        <Subtitle>Boka om & avboka enkelt</Subtitle>
        <Separator height={5} />
        <StarsBox>
          <FullStar size={12} />
          <FullStar size={12} />
          <FullStar size={12} />
          <FullStar size={12} />
          <HalfStar size={12} />
        </StarsBox>
      </TextBox>
      <ButtonBox>
        <InstallButton>HÄMTA</InstallButton>
      </ButtonBox>
    </Container>
  );
};

export default AppstoreBanner;
