import React from 'react';
import { Form, Separator } from '@bokadirekt/apps-reviews-form';
import styled from 'styled-components';
import TimeCard from './TimeCard';
import LabeledSection from './LabeledSection';
import AppstoreBanner from './AppstoreBanner';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: '#23A994' }
  }
});

const TopPadding = styled.div`
  display: block;
  height: 50px;
  @media (min-width: 768px) {
    height: 0px;
  }
`;

const TitleText = styled.div`
  font-family: 'Gibson-Regular';
  font-size: 16px;
  line-height: 20px;
  color: #2a2e43;
  text-align: center;
`;

export interface Props {
  serviceName?: string;
  onDateSelected?: (
    date: Date | undefined,
    type: 'today' | 'week' | 'month' | 'custom'
  ) => void;
  onViewInApp?: () => void;
}

const Reminder: React.FC<Props> = ({
  serviceName,
  onDateSelected = (): void => undefined,
  onViewInApp
}: Props) => {
  const now = DateTime.now().toLocal();
  const today2000 = now.set({ hour: 20, minute: 0, second: 0, millisecond: 0 });
  const laterToday =
    now.toMillis() < today2000.minus({ hours: 1, minutes: 30 }).toMillis()
      ? today2000
      : undefined;
  const tomorrow0800 = today2000
    .plus({ days: 1 })
    .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  const inAWeek = now
    .plus({ days: 7 })
    .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  const inAMonth = now
    .plus({ months: 1 })
    .set({ hour: 8, minute: 0, second: 0 });

  const [date, setDate] = React.useState(
    (laterToday ?? tomorrow0800).toJSDate()
  );

  const [pickCustomDate, setPickCustomDate] = React.useState(false);
  const onPickCustomDateCallback = React.useCallback(
    (date: DateTime | null) => {
      if (date) {
        onDateSelected(date.toJSDate(), 'custom');
      }
    },
    [setDate, setPickCustomDate]
  );

  const calendarOpenCallback = React.useCallback(() => {
    setPickCustomDate(true);
  }, [setPickCustomDate]);

  const calendarCloseCallback = React.useCallback(() => {
    setPickCustomDate(false);
  }, [setPickCustomDate]);

  return (
    <>
      <TopPadding />
      <Form paddingTop={0}>
        <TitleText>
          Vill du att vi påminner dig när det är dags för nästa {serviceName}?
        </TitleText>
        <Separator height={24} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <TimeCard
              icon="moon"
              title="Senare ikväll"
              subtitle={
                laterToday
                  ? laterToday.toFormat('EEE HH:mm', {
                      locale: 'sv'
                    })
                  : undefined
              }
              onClick={(): void => {
                onDateSelected(laterToday?.toJSDate(), 'today');
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TimeCard
              icon="cal3"
              title="Om en vecka"
              subtitle={inAWeek.toFormat('EEE HH:mm', {
                locale: 'sv'
              })}
              onClick={(): void => {
                onDateSelected(inAWeek.toJSDate(), 'week');
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <TimeCard
              icon="cal5"
              title="Om en månad"
              subtitle={inAMonth.toFormat('EEE HH:mm', {
                locale: 'sv'
              })}
              onClick={(): void => {
                onDateSelected(inAMonth.toJSDate(), 'month');
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <TimeCard
              icon="cal9"
              title="Välj datum & tid"
              onClick={(): void => {
                setPickCustomDate(true);
              }}
            />
            <ThemeProvider theme={defaultMaterialTheme}>
              <MuiPickersUtilsProvider utils={LuxonUtils} locale="sv">
                <DateTimePicker
                  variant="dialog"
                  value={date}
                  disableToolbar
                  TextFieldComponent={(): null => null}
                  open={pickCustomDate}
                  onOpen={calendarOpenCallback}
                  onClose={calendarCloseCallback}
                  onChange={onPickCustomDateCallback}
                  ampm={false}
                  disablePast
                  minutesStep={5}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div>
        </div>
        <Separator height={104} withBorder={true} />
        <Separator height={24} />
        <LabeledSection title="HÄMTA VÅR APP">
          <AppstoreBanner onClick={onViewInApp} />
        </LabeledSection>
      </Form>
    </>
  );
};

export default Reminder;
